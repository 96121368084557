import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import { LayoutLp, SEO, AssetImage, LWrap } from '../../../components/_index';
import Slider from 'react-slick';
import { useMobile } from '../../../utils/use-series';
import '../../../assets/_sass/page/sp/afternoon.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const isSp = useMobile();
  const settings = {
    dots: true,
    arrows: false,
    autoplay: false,
    speed: 1500,
  };

  // SPテキストの「続きを見る」をセクションごとに制御
  const [expandedSections, setExpandedSections] = useState<{
    [key: string]: boolean;
  }>({});

  const handleShowMore = (sectionId: string) => {
    setExpandedSections({
      ...expandedSections,
      [sectionId]: !expandedSections[sectionId],
    });
  };

  return (
    <LayoutLp isAfternoon={true}>
      <SEO
        title={frontmatter?.title}
        description={frontmatter?.description}
        ogimage={`${process.env.BASE_URL}/assets/images/sp/afternoon/ogimage.png`}
      />
      <main>
        <div className="p_afternoon">
          <section className="lp_kv">
            {!isSp ? (
              <figure className={`imgWrapper`}>
                <AssetImage
                  src="/assets/images/sp/afternoon/kv.png"
                  alt=""
                  loading="lazy"
                />
              </figure>
            ) : (
              <figure className={`imgWrapper`}>
                <AssetImage
                  src="/assets/images/sp/afternoon/kv__sp.png"
                  alt=""
                  loading="lazy"
                />
              </figure>
            )}
          </section>
          <div className="lp_kvLead">
            <div className="lp_kvLeadText">
              ロイヤルパークホテルズの
              <br className="u_sp" />
              「アフタヌーンティーセット」
              <br />
              各ホテル限定メニューをこの機会にぜひお楽しみください
            </div>
          </div>

          <section className="lp_sect u_pt100">
            <div className="lp_sectWrapper">
              <div className="lp_sectSlider">
                {!isSp ? (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider08.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                ) : (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider08__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                )}
              </div>
              <div className="lp_lead">
                <div className="lp_contentsNumber">
                  <span>01</span>ザ ロイヤルパークキャンバス 札幌大通公園
                </div>
                <div className="lp_setPlanTitle">
                  KAMUY パークビューアフタヌーンティー
                  <br />
                  ～ちょっぴり大人なダークなひととき～
                </div>
                <p
                  className={`lp_sectLead lp_sectLead_showMore ${
                    expandedSections['section1'] ? 'expanded' : ''
                  }`}
                >
                  札幌の秋にちょっぴりダークなアフタヌーンティーが登場。モンスターを模したさつまいものモンブラン、道産卵を使用した厚焼きサンドイッチもご用意。フリードリンクとともに、ほっこりハロウィン気分をお楽しみください。</p>
                {isSp && (
                  <span
                    className="show-more"
                    onClick={() => handleShowMore('section1')}
                  >
                    {expandedSections['section1']
                      ? '表示を減らす'
                      : '続きを見る'}
                  </span>
                )}
                <p className="lp_moreButton">
                  <Link
                    to="https://www.royalparkhotels.co.jp/canvas/sapporoodoripark/news/vkmwi72q02ad/"
                    target="_blank"
                  >
                    MORE
                    <i className="icon-arrow"></i>
                  </Link>
                </p>
              </div>
            </div>
          </section>

          <section className="lp_sect u_pt100">
            <div className="lp_sectWrapper lp_sectWrapper__reverse">
              <div className="lp_sectSlider">
                {!isSp ? (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider01.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider01_02.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                ) : (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider01__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider01_02__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                )}
              </div>
              <div className="lp_lead">
                <div className="lp_contentsNumber">
                  <span>02</span>仙台ロイヤルパークホテル
                </div>
                <div className="lp_setPlanTitle">
                  秋の味覚で彩る
                  <br />
                  アフタヌーンティーセット
                </div>
                <p
                  className={`lp_sectLead lp_sectLead_showMore ${
                    expandedSections['section1'] ? 'expanded' : ''
                  }`}
                >
                  イタリアマロンを使用した濃厚なモンブランやかぼちゃのクレームブリュレ、キノコのペースト入りクロックムッシュや大学芋のキッシュなど、秋の味覚で彩るアフタヌーンティーを、色づくガーデンを眺めながらご堪能ください。</p>
                {isSp && (
                  <span
                    className="show-more"
                    onClick={() => handleShowMore('section1')}
                  >
                    {expandedSections['section1']
                      ? '表示を減らす'
                      : '続きを見る'}
                  </span>
                )}
                <p className="lp_moreButton">
                  <Link
                    to="https://www.srph.co.jp/pickup/yutss1ly71jw/"
                    target="_blank"
                  >
                    MORE
                    <i className="icon-arrow"></i>
                  </Link>
                </p>
              </div>
            </div>
          </section>

          <section className="lp_sect">
            <div className="lp_sectWrapper">
              <div className="lp_sectSlider">
                {!isSp ? (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider02.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider02_02.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                ) : (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider02__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider02_02__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                )}
              </div>
              <div className="lp_lead">
                <div className="lp_contentsNumber">
                  <span>03</span>ロイヤルパークホテル（東京・日本橋）
                </div>
                <div className="lp_setPlanTitle">
                  ハロウィンアフタヌーンティー
                </div>
                <p
                  className={`lp_sectLead lp_sectLead_showMore ${
                    expandedSections['section2'] ? 'expanded' : ''
                  }`}
                >
                  月夜にたたずむ黒猫やオバケ、初登場となるおおかみ男などコワかわいいキャラクターが並ぶハロウィンアフタヌーンティー。
                  <br />
                  スイーツにもセイボリーにもハロウィンモチーフ満載♪ほっこりした優しい味わいのカボチャのスープで心温まるひとときを。
                </p>
                {isSp && (
                  <span
                    className="show-more"
                    onClick={() => handleShowMore('section2')}
                  >
                    {expandedSections['section2']
                      ? '表示を減らす'
                      : '続きを見る'}
                  </span>
                )}
                <p className="lp_moreButton">
                  <Link
                    to="https://www.rph.co.jp/restaurants/plan/rnbg8vt7cg/"
                    target="_blank"
                  >
                    MORE
                    <i className="icon-arrow"></i>
                  </Link>
                </p>
              </div>
            </div>
          </section>

          <section className="lp_sect">
            <div className="lp_sectWrapper lp_sectWrapper__reverse">
              <div className="lp_sectSlider">
                {!isSp ? (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider03.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider03_02.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                ) : (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider03__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider03_02__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                )}
              </div>
              <div className="lp_lead">
                <div className="lp_contentsNumber">
                  <span>04</span>ロイヤルパークホテル（東京・日本橋）
                </div>
                <div className="lp_setPlanTitle">
                  チャイニーズ
                  <br />
                  ハロウィンアフタヌーンティー
                </div>
                <p
                  className={`lp_sectLead lp_sectLead_showMore ${
                    expandedSections['section3'] ? 'expanded' : ''
                  }`}
                >
                  オバケやフランケンシュタインに変身したぶたさんの肉饅頭やパンダ饅頭など、可愛らしく仮装した点心やスイーツが彩るチャイニーズハロウィンアフタヌーンティー。モンスターやコウモリなどのハロウィンならではのモチーフも並びます。
                  <br />
                  
                </p>
                {isSp && (
                  <span
                    className="show-more"
                    onClick={() => handleShowMore('section3')}
                  >
                    {expandedSections['section3']
                      ? '表示を減らす'
                      : '続きを見る'}
                  </span>
                )}
                <p className="lp_moreButton">
                  <Link
                    to="https://www.rph.co.jp/restaurants/plan/787zs2tvv/"
                    target="_blank"
                  >
                    MORE
                    <i className="icon-arrow"></i>
                  </Link>
                </p>
              </div>
            </div>
            {/* <div className="lp_leadSub">
              <figure className="imgWrapper">
                <AssetImage
                  src="/assets/images/sp/afternoon/sect02_sub.png"
                  alt=""
                  loading="lazy"
                />
              </figure>
              <p className="lp_sectLead">
                <span className="lp_sectLead_sub">
                  バレンタイン限定でご提供
                </span>
                デートや女子会に最適な大人かわいいバレンタイン限定アフタヌーンティーをお楽しみください。
                <span className="lp_moreButton">
                  <Link
                    to="https://www.rph.co.jp/restaurants/plan/rlrrbr8g6u/"
                    target="_blank"
                  >
                    MORE
                    <i className="icon-arrow"></i>
                  </Link>
                </span>
              </p>
            </div> */}
          </section>

          <section className="lp_sect">
            <div className="lp_sectWrapper">
              <div className="lp_sectSlider">
                {!isSp ? (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider04.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider04_02.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider04_03.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                ) : (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider04__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider04_02__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider04_03__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                )}
              </div>
              <div className="lp_lead">
                <div className="lp_contentsNumber">
                  <span>05</span>ザ ロイヤルパークホテル アイコニック 東京汐留
                </div>
                <div className="lp_setPlanTitle">
                AFTERNOON TEA
                <br />
                with SIROCCO
                <br />
                「シャインマスカット&マロン」
                </div>
                <p
                  className={`lp_sectLead lp_sectLead_showMore ${
                    expandedSections['section4'] ? 'expanded' : ''
                  }`}
                >
                  シャインマスカットとマロンをふんだんに取り入れた実りの秋のアフタヌーンティー！
                  <br />
                  大粒のシャインマスカットがトップを飾る「シャインマスカット パフェ」もマロンクリームのマカロンもどれも魅力的なラインナップに迷ってしまうかも？！
                </p>
                {isSp && (
                  <span
                    className="show-more"
                    onClick={() => handleShowMore('section4')}
                  >
                    {expandedSections['section4']
                      ? '表示を減らす'
                      : '続きを見る'}
                  </span>
                )}
                <p className="lp_moreButton">
                  <Link
                      to="https://www.royalparkhotels.co.jp/ic/tokyoshiodome/news/0p1jfwdzu6/"
                      target="_blank"
                    >
                      MORE
                    <i className="icon-arrow"></i>
                  </Link>
                </p>
              </div>
            </div>
          </section>

          <section className="lp_sect">
            <div className="lp_sectWrapper lp_sectWrapper__reverse">
              <div className="lp_sectSlider">
                {!isSp ? (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider05.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                ) : (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider05__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                )}
              </div>
              <div className="lp_lead">
                <div className="lp_contentsNumber">
                  <span>06</span>丸ノ内ホテル
                </div>
                <div className="lp_setPlanTitle">
                  ハロウィンアフタヌーンティー
                </div>
                <p
                  className={`lp_sectLead lp_sectLead_showMore ${
                    expandedSections['section5'] ? 'expanded' : ''
                  }`}
                >
                  秋色のシックに彩るスイーツが大人のハロウィンを演出します！ミルク・ホワイト・ブラック3種類のチョコレートを使用した懐中時計に十字架のチョコレートで遊び心を加えて、ハロウィン気分をより一層盛りあげます。
                </p>
                {isSp && (
                  <span
                    className="show-more"
                    onClick={() => handleShowMore('section5')}
                  >
                    {expandedSections['section5']
                      ? '表示を減らす'
                      : '続きを見る'}
                  </span>
                )}
                <p className="lp_moreButton">
                  <Link
                    to="https://www.marunouchi-hotel.co.jp/tpx-afternoontea202409/"
                    target="_blank"
                  >
                    MORE
                    <i className="icon-arrow"></i>
                  </Link>
                </p>
              </div>
            </div>
            {/* <div className="lp_leadSub">
              <figure className="imgWrapper">
                <AssetImage
                  src="/assets/images/sp/afternoon/sect03_sub.png"
                  alt=""
                  loading="lazy"
                />
              </figure>
              <p className="lp_sectLead">
                <span className="lp_sectLead_sub">
                  さらにテイクアウトで楽しめる
                </span>
                  苺と桜のロールケーキを季節限定でお楽しみください
                <span className="lp_moreButton">
                  <Link
                    to=""
                    target="_blank"
                  >
                    MORE
                    <i className="icon-arrow"></i>
                  </Link>
                </span>
              </p>
            </div> */}
          </section>

          <section className="lp_sect">
            <div className="lp_sectWrapper">
              <div className="lp_sectSlider">
                {!isSp ? (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider06.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider06_02.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider06_03.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider06_04.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                ) : (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider06__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider06_02__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider06_03__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider06_04__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                )}
              </div>
              <div className="lp_lead">
                <div className="lp_contentsNumber">
                  <span>07</span>横浜ロイヤルパークホテル
                </div>
                <div className="lp_setPlanTitle">
                  ランドマークアフタヌーンティー“和と洋の饗宴”
                </div>
                <p
                  className={`lp_sectLead lp_sectLead_showMore ${
                    expandedSections['section6'] ? 'expanded' : ''
                  }`}
                >
                  シャインマスカットや栗、かぼちゃなどの秋の味覚とともに趣向を凝らした和洋折衷のアフタヌーンティーを土・日・祝日限定でご提供します。
                  <br />
                  秋の風情とともに、和と洋の上品さや華やかさを見事に融合させたスイーツやセイボリーをお楽しみください。
                </p>
                {isSp && (
                  <span
                    className="show-more"
                    onClick={() => handleShowMore('section6')}
                  >
                    {expandedSections['section6']
                      ? '表示を減らす'
                      : '続きを見る'}
                  </span>
                )}
                <p className="lp_moreButton">
                  <Link
                      to="https://www.yrph.com/restaurants/teatime/teatime/#coffret"
                      target="_blank"
                    >
                    MORE
                    <i className="icon-arrow"></i>
                  </Link>
                </p>
              </div>
            </div>
            {/* <div className="lp_leadSub">
              <figure className="imgWrapper">
                <AssetImage
                  src="/assets/images/sp/afternoon/sect04_sub.png"
                  alt=""
                  loading="lazy"
                />
              </figure>
              <p className="lp_sectLead">
                <span className="lp_sectLead_sub u_tac">
                  さらにテイクアウトで楽しめる
                </span>
                わらび餅粉の入ったしっとり・もちもちの生地に苺とマスカルポーネクリームのさっぱりとした味わいです。
                <span className="lp_moreButton">
                  <Link
                    to="https://www.srph.co.jp/pickup/ifvefyjko/"
                    target="_blank"
                  >
                    MORE
                    <i className="icon-arrow"></i>
                  </Link>
                </span>
              </p>
            </div> */}
          </section>

          <section className="lp_sect">
            <div className="lp_sectWrapper lp_sectWrapper__reverse">
              <div className="lp_sectSlider">
                {!isSp ? (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider07.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider07_02.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider07_03.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider07_04.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider07_05.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider07_06.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider07_07.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                ) : (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider07__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider07_02__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider07_03__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider07_04__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider07_05__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider07_06__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider07_07__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                )}
              </div>
              <div className="lp_lead">
                <div className="lp_contentsNumber">
                  <span>08</span>ザ ロイヤルパークホテル アイコニック 大阪御堂筋
                </div>
                <div className="lp_setPlanTitle">
                アフタヌーンティー
                <br />
                L'Automne
                </div>
                <p
                  className={`lp_sectLead lp_sectLead_showMore ${
                    expandedSections['section7'] ? 'expanded' : ''
                  }`}
                >
                  「La Belle Assiette（ラ ベル アシェット）」でご提供のシグネチャーディッシュのテリーヌもミニサイズで登場する秋の味覚とフレンチが融合したアフタヌーンティー。秋の彩りを添えた絶妙なマリアージュをご堪能ください。
                </p>
                {isSp && (
                  <span
                    className="show-more"
                    onClick={() => handleShowMore('section7')}
                  >
                    {expandedSections['section7']
                      ? '表示を減らす'
                      : '続きを見る'}
                  </span>
                )}
                <p className="lp_moreButton">
                  <Link
                    to="https://www.royalparkhotels.co.jp/ic/osakamidosuji/news/ft7nj6pfapap/"
                    target="_blank"
                  >
                    MORE
                    <i className="icon-arrow"></i>
                  </Link>
                </p>
              </div>
            </div>
          </section>

          <section>
            <div className="instagram">
              <LWrap>
                <Link
                  to="https://www.instagram.com/royalparkhotels__official/"
                  target="_blank"
                >
                  <AssetImage
                    src="/assets/images/sp/afternoon/instagram.png"
                    alt=""
                    loading="lazy"
                  />
                </Link>
              </LWrap>
            </div>
          </section>
        </div>
      </main>
    </LayoutLp>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
